import { useDispatch, useSelector } from "react-redux";

import { setDataVar } from "../../../../redux/data";
import CoursesComp from "../CoursesComp/CoursesComp";

import './CourseChooseModal.css'

const CourseChooseModal = () =>
{   
    const {courseModal} = useSelector((state) => state.data);
    const dispatch = useDispatch();

    return (
        <>
            <div className={"modal "+courseModal}>
                <div className="modal-background"></div>
                <div className="modal-card m-0 modalChooseCourse">
                    <center className="fitt">
                        <section className="modal-card-body modal-rounded-box fitt notagrayback">
                            <button className="delete is-pulled-right" aria-label="close" onClick={() => dispatch(setDataVar({courseModal : ""}))}></button>
                            <div className='m-4'>
                                <center><h1 className="title is-3 notaforecolor underline mb-5"><b>Your Courses</b></h1></center>
                                <CoursesComp lblvisible={false}/>
                            
                            </div>
                        </section>
                    </center>
                </div>
            </div>
        </>
    );
}
export default CourseChooseModal;