import React from 'react';
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Provider, useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Login from './Components/AuthFrame/Login/Login';
import SignUp from './Components/AuthFrame/SignUp/SignUp';
import ForgotPass from './Components/AuthFrame/ForgotPass/ForgotPass';

import store from './redux/store.js'
import HomePage from './Components/MainFrame/HomePage/HomePage';
import VerifyWindow from './Components/AuthFrame/VerifyWindow/VerifyWindow'
import ResetPass from './Components/AuthFrame/ResetPass/ResetPass';

import './index.css';
import CoursePage from './Components/MainFrame/Courses/CoursePage/CoursePage';
import LecturePage from './Components/MainFrame/Lectures/LecturePage/LecturePage';
import ReadingPage from './Components/MainFrame/Reading/ReadingPage/ReadingPage';

const routerAuth = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signin",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/forgotPass",
    element: <ForgotPass />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);
const routerNota = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/resetpass/:token",
    element: <ResetPass />
  },
  {
    path: "/verify/:token",
    element: <VerifyWindow />,
  },
  {
    path: "/course/:coursetitle",
    element: <CoursePage />,
  },
  {
    path: "/course/:coursetitle/lecture/:lecturetitle",
    element: <LecturePage />,
  },
  {
    path: "/course/:coursetitle/reading/:readingtitle",
    element: <ReadingPage />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },

]);


const App = () =>
{
  const { isvalid } = useSelector((state) => state.user);
  const router = isvalid ? routerNota : routerAuth;

  return  (<GoogleOAuthProvider clientId="629808933915-l07gp1ninsqkkhhcktbme08erqi1fp9o.apps.googleusercontent.com">
              <RouterProvider router={router} />
           </GoogleOAuthProvider>);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
        <App />
  </Provider>
);
