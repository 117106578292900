import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useDispatch } from "react-redux";

import { setVar } from "../../../redux/user";
import { backend, pythonUrl, VIcon, XIcon } from "../../../Data/functions";

import AuthFrame from "../AuthFrame";
import './SignUp.css'

const typeFullname    = "Full Name";
const typeemail       = "email";
const typepassword    = "password";
const typeconfirmpass = "Re-Enter Password";

export const Field = ({type,othertype,placeholder,data,setData,error,setError,additionError,showIcon}) =>
{
    const formalType = type[0].toUpperCase()+type.slice(1);
    return (
        <div className="field mb-4">
            <h2 className="title is-6 m-0 mb-2">{formalType+" *"}</h2>
            <div className="is-flex is-flex-direction-row">
              <input className={"input is-rounded "+(!!error[type] || additionError ? "is-danger has-text-danger" : "")} 
                type={othertype ? othertype : type.toLowerCase()} placeholder={placeholder} 
                onClick={(e) => e.currentTarget.value === (error[type]) ? setError({...error,[type] : false}) : null}
                value={!!error[type] ? error[type] : data[type]} 
                onChange={(e) => {setError({...error,[type] : false});setData({...data,[type] : typeemail !== [type] ? e.currentTarget.value : e.currentTarget.value.trim()})}}
              />
              {showIcon}
            </div>
      </div>
    );
}
const isPassValid = (password) =>
{
  const  minimum_length = 8;
  // Check if the password has at least minimum_length characters
  return {
    "Minimum 8 Characters Long" : password.length >= minimum_length,
    "Minimum 1 Upper Case Character" : /[A-Z]/.test(password),
    "Minimum 1 Lower Case Character" : /[a-z]/.test(password),
    "Minimum 1 Numeric Character" : /\d/.test(password)
  };
  //if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) 
  //  return false;
}
const isEmailValid = (email) =>
{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && email !== "";
}
const SignUpFrame = () => 
{ 
    const [data,setData] = useState({
      [typeFullname   ] : "",
      [typeemail      ] : "",
      [typepassword   ] : "",
      [typeconfirmpass] : "",
    });
    const [error,setError] = useState({
      [typeFullname   ] : false,
      [typeemail      ] : false,
      [typepassword   ] : false,
      [typeconfirmpass] : false,
    });
    const additionErrors={
      [typeconfirmpass] : data[typeconfirmpass] !== data[typepassword],
      [typeemail] : !isEmailValid(data[typeemail]),
      [typepassword] : isPassValid(data[typepassword]),
      [typeFullname] : data[typeFullname] === "" || data[typeFullname] === undefined,
    }
    const [isloading,setIsLoading] = useState({});
    const [showpass,setShowpass] = useState(false);
    const [showconfpass,setShowconfpass] = useState(false);
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();

    let errors = Object.values(error).some((e) => {return e}) || Object.values({...additionErrors,[typepassword] : false}).some((e) => {return e}) || !Object.values(additionErrors[typepassword]).every((e) => {return e});

    const sendSignup = async () =>
    {
        setIsLoading({...isloading,"login" : true});
        try
        {
          let params = 
          {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded'  },
              data: new URLSearchParams({"password" : data[typepassword],"fullname" : data[typeFullname]})
          }
          let res = await backend(pythonUrl+"/user/"+data[typeemail]+"/register",params,[false,setCookies,null],dispatch);
          if(res.status === 409)
            setError({...error,[typeemail] : "Email Already Exists"})
          else if(res.status === 500)
            setError({...error,[typeemail] : "Invalid Email"})
          else if(res.status === 400)
            setError({...error,[typepassword] : "Invalid Password"})
          else if(res.status === 200)
            dispatch(setVar({"isvalid" : "/verify/null","userEmail" : data[typeemail],"fullname" : data[typeFullname]}));
          setIsLoading({...isloading,"login" : false});
      
        }
        catch(err)
        {
          alert(err);
          setIsLoading({...isloading,"login" : false});
        }
    }

    return (
    <AuthFrame childrens={<>
      <div className="box signBox mt-2">
        <div className="m-3">
          <h1 className="title is-5">Start for free Today 👏</h1>
          <h1 className="subtitle is-6">7 Day free trial, followed by $5.99/month.</h1>

          <div className="is-divider mb-4 mt-1" data-content="continue"></div>

          <Field type={typeFullname} data={data} setData={setData} placeholder={"Full Name"} error={error} setError={setError} additionError={additionErrors[typeFullname]}/>
          <Field type={typeemail} data={data} setData={setData}  placeholder={"example@notanotes.com"} error={error} setError={setError} additionError={additionErrors[typeemail]} />
          
          <div className="m-3"></div>
        
          <div className="is-relative">
            <Field type={typepassword} othertype={showpass || !!error[typepassword] ? "text" : false} 
                data={data} setData={setData} error={error} setError={setError} 
                showIcon={
              <span className="inputIcon" onClick={() => setShowpass(!error[typepassword] ? !showpass : showpass)} >
                <i className={"fa-regular fa-eye" + ((!showpass || !!error[typepassword]) ? "-slash" : "")} />
              </span>} additionError={!Object.values(additionErrors[typepassword]).every((e,index) => {return <React.Fragment index={index}>{e}</React.Fragment>})} placeholder={"Create a password"}/>
            <div className="box passwordBox">
              <div className='triangleBox'/>
              {Object.entries(additionErrors[typepassword]).map(([key,value]) => {
                return (
                      <div className='columns mb-1'>
                         <div className='column is-narrow' style={{"paddingBottom" : "0"}}>{value ? VIcon : XIcon}</div>
                         <div className='column is-narrow' style={{"paddingBottom" : "0"}}>{key}</div>
                       </div>)
              })}
            </div>
          </div>
          <div className="is-relative">
            <Field type={typeconfirmpass} othertype={showconfpass || !!error[typeconfirmpass] ? "text" : "password"} 
                data={data} setData={setData} error={error} setError={setError} 
                showIcon={
              <span className="inputIcon" onClick={() => setShowconfpass(!error[typeconfirmpass] ? !showconfpass : showconfpass)} >
                <i className={"fa-regular fa-eye" + ((!showconfpass || !!error[typeconfirmpass]) ? "-slash" : "")}/>
              </span>} placeholder={"Confirm password"} additionError={additionErrors[typeconfirmpass]}/>
          </div>
          <div>
            <div className={"field" + (errors ? " custom-disableddiv" : "")}>
              <center>
                  <button className={"button notabackcolor has-text-white is-rounded is-fullwidth" + (isloading["login"] ? " is-loading" : "") 
                  + (errors ? " custom-disabled" : "")} onClick={() => sendSignup()}>Sign Up</button>
              </center>
            </div>
          </div>
          <center className="mt-3">{"Already have an account? "}<Link to="/signin" className="notaforecolor" replace><b>Sign In</b></Link></center>
        </div>
      </div>
    </>}/>);
}

export default SignUpFrame;