/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState } from "react";

import {  backend, getJwt, pythonUrl} from "../../../../Data/functions";
import { NavBarButton } from "../../NavBarComp/NavBarComp";
import MainFrame from "../../MainFrame";

import UploadModal from "./UploadModal/UploadModal";
import PdfViewer from "../PdfViewer/PdfViewer";
import './ReadingPage.css'
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import { useDispatch, useSelector } from "react-redux";
import { ModuleChatBtn } from "../../Module/ModuleChatBtn";
import { ModuleChat } from "../../Module/ModuleChat";

  
export const SpecialInput = ({value,setValue,maxPage}) =>
{
    const [inputWidth,setInputWidth] = useState(0);
    const adjustWidth = () =>
    {
        const tempElement = document.createElement('span');
        tempElement.style.visibility = 'hidden';
        tempElement.style.whiteSpace = 'pre';
        tempElement.textContent = value;
        document.body.appendChild(tempElement);
        setInputWidth(tempElement.offsetWidth)
        document.body.removeChild(tempElement);
    }
    const onChange = (e) =>
    {
        if(!isNaN(e.currentTarget.value) && e.currentTarget.value !== "")
            setValue(Math.min(maxPage,Math.max(0,parseInt(e.currentTarget.value))));
    }
    useEffect(() => {adjustWidth()} ,[value])
    return <input className={'input is-pulled-right notagraydarkback notaforecolor mr-3 pagebtn p-3'}  type="text" value={value} style={{"width" : "calc(2*(0.75em) + "+(inputWidth)+"px"}} onChange={onChange}/>
}

const ReadingPage = () =>
{
    const {readingtitle,coursetitle} = useParams();
    const [cookies, setCookies, ] = useCookies();
    const { rememberme } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [uploadModal,setUploadModal] = useState();
    const [file, setFile] = useState(false);
    const [fileURL, setFileURL] = useState(false);
    const [maxPage, setMaxPage] = useState(0);
    const [page,setPage] = useState(-1);
    const [isModalLoading,setModalLoading] = useState(false);
    
    const [data,setData] = useState({"summary" : "","bulletpoints" : []});

    const [chatVisible,setChatVisible] = useState(false);

    const zoomPluginInstance = zoomPlugin();

    useEffect(() => 
    {
        const putPdfFile = async () =>
        {
            if(file) 
            {
                if(!fileURL)
                {
                    
                    setData({...data,"summary" : <div className="lds-ring"><div></div><div></div><div></div><div></div></div>});
                    let  formData = new FormData();
                    formData.append("pdf", new File([file[0]], "myfile.pdf"));
                
                    let  params = 
                    {
                        method: 'PUT',
                        headers: { 'Content-Type': `multipart/form-data`,"Authorization"  : getJwt(cookies,rememberme)  },
                        data : formData
                    }
                    let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/reading/"+readingtitle+"/",params,["body",setCookies,rememberme],dispatch);
                    if(res.status === 200)
                    {
                        setUploadModal("");
                        setModalLoading(false);
                        setFileURL(URL.createObjectURL(file[0]));
                    }
                    else if(res.status !== 504)
                        alert(res.data)
                    
                    setModalLoading(false);
                    setData({...data,"summary" : <></>});
                }
            }
        }
        putPdfFile();
    },[file])
    useEffect(() => 
    {
        console.log(rememberme);
        const getPdfFile = async () =>
        {
            let  params = 
            {
                method: 'GET',
                headers: {"Authorization"  : getJwt(cookies,rememberme),  },
                responseType: 'blob',
                params : {"data_format" : "file"} 
            }
            let  res = await backend(pythonUrl+"/user/course/"+coursetitle+"/reading/"+readingtitle+"/",params,["body",setCookies,rememberme],dispatch);
            if(res.status === 200)
            {
                setFileURL(URL.createObjectURL(res.data));
                setFile(new File([res.data], "myfile.pdf"));
                setUploadModal("");
                setPage(0);
            }
            else if(res.status === 401)
                alert("User isn't Authenticated")
            else
                setUploadModal("is-active");
        }
        getPdfFile();
    },[])
    useEffect(() => 
    {
        const getPdfData = async () =>
        {
            if(fileURL && fileURL !== undefined)
            {
                let  params = 
                {
                    method: 'GET',
                    headers: {"Authorization"  : getJwt(cookies,rememberme),  },
                }
                let  res = await backend(pythonUrl+"/user/course/"+coursetitle+"/reading/"+readingtitle+"/page/"+page,params,["body",setCookies,rememberme],dispatch);
                if(res.status === 200)
                {
                    setData({...data,"summary" : 
                    <h1 className="subtitle is-6">
                        {res.data.summary}
                    </h1>,"bulletpoints":
                    res.data.bullet_points.map((point,index) => 
                    <React.Fragment key={index}>
                        <div className="point" key={`point-${index}`}>
                            <i className="fas fa-chevron-circle-right"/>
                            {point.text.trim()}
                        </div>
                    </React.Fragment>)});

                }
                else
                    alert(res.data)
            }
        }
        getPdfData();
    },[page])

    return (
        <MainFrame childrens={
            <>     
                <UploadModal modal={uploadModal} setModal={setUploadModal} setFiles={setFile} isLoading={isModalLoading} setIsLoading={setModalLoading}/>
                <div className="columns is-centered is-vcentered fullHeight">
                    <div className="column box rounded-box mb-0" style={{"width" : "50%"}} >
                        <div className="m-5 fullHeight">
                            <div className="columns is-vcentered">
                                <div className="column is-narrow m-0 p-0">
                                    <h1 className="title is-6 notaforecolor ml-2"><b>Original File</b></h1>
                                </div>
                                <div className="column m-0 p-0">
                                    <SpecialInput value={page} setValue={setPage} maxPage={maxPage}/>
                                    <div className="columns is-centered is-vcentered">
                                        <div className="column is-narrow"><zoomPluginInstance.ZoomInButton /></div>
                                        <div className="column is-narrow"><zoomPluginInstance.ZoomOutButton /></div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="pdfView scrollbarDiv">
                                <PdfViewer fileURL={fileURL} page={page} setPage={setPage} setMaxPages={setMaxPage} zoomPluginInstance={zoomPluginInstance}/>
                            </div>
                        </div>
                    </div>
                    <div className="column box rounded-box mb-0 mr-5">
                        <div className="m-5 fullHeight">
                            <ModuleChat chatVisible={chatVisible} moduletitle={"reading"} modulename={readingtitle}/>
                            <div className="height-40 mb-2">
                                <h1 className="title is-6 notaforecolor mb-1"><b>Summary</b></h1>
                                <div className={"height-85 scrollY scrollbarDiv"}>
                                    {data["summary"]}
                                </div>
                            </div>
                            <div className="height-50 scrollY scrollbarDiv">
                                <h1 className="title is-6 notaforecolor mb-1"><b>Bullet Points</b></h1>
                                <div className="points-container">
                                    {data["bulletpoints"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModuleChatBtn chatVisible={chatVisible} setChatVisible={setChatVisible}/>
            </>
        } navBarChildren={<NavBarButton />}/>
    );
}

export default ReadingPage;