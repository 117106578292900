import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { setVar } from "../../../redux/user";
import {  backend, pythonUrl, VIcon, XIcon } from "../../../Data/functions";

import AuthFrame from "../AuthFrame";
import './ResetPass.css'

const isPassValid = (password) =>
{
  const  minimum_length = 8;
  // Check if the password has at least minimum_length characters
  return {
    "Minimum 8 Characters Long" : password.length >= minimum_length,
    "Minimum 1 Upper Case Character" : /[A-Z]/.test(password),
    "Minimum 1 Lower Case Character" : /[a-z]/.test(password),
    "Minimum 1 Numeric Character" : /\d/.test(password)
  };
  //if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) 
  //  return false;
}
const type = "password";
const ResetPass = () =>
{
    const [password,setPassword] = useState("")
    const [passwordConfirm,setPasswordConfirm] = useState("")
    const [passwordValid,setPasswordValid] = useState(isPassValid(password))
    const [isLoading,setIsLoading] = useState("")
    const [error,setError] = useState({[type] : false})
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(() => setPasswordValid(isPassValid(password)),[password])
    
    const confirmPass = async (e) =>
    {
        setIsLoading("is-loading");
        try
        {
            let params = 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'  },
                data : new URLSearchParams({"token" : token,"password" : password})
            }
            let res = await backend(pythonUrl+"/user/password/",params,[false,setCookies,null],dispatch);
            if(res.status === 400)
                setError({...error,[type] : "Password/Token Invalid"})
            else if(res.status === 401)
                alert("Token Expired, Please reset password again.")
            else if(res.status === 404)
                alert("User not found, Please contact us at info@notanotes.com.")
            else if(res.status === 200)
                dispatch(setVar({"isvalid" : false}));
        }
        catch(err)
        {
            alert(err);
        }
        setIsLoading("");
    }
    return (<AuthFrame childrens={<>
                <div>
                    <div className="box forgotbox mt-4">
                        <div className="m-4">
                        <div className="columns">
                            <div className="column is-narrow mr-0 pr-0">
                                <div className="lockIcon"/>
                            </div>
                            <div className="column is-narrow">
                                <h1 className="title is-5">Create New Password</h1>
                                <h1 className="subtitle is-6">Enter a new password for login your account</h1>
                            </div>
                        </div>
                            <h1 className="title is-6 mb-2 mt-5 ml-1">New Pasword *</h1>
                            <input className={"input " + (error[type] ? "is-danger has-text-danger" : "")} type={error[type] ? "text" :"password"} placeholder={"New Password"} value={error[type] ? error[type] : password} onClick={() => setError({...error,[type] : false})} onChange={(e) => setPassword(e.currentTarget.value)} />
                            <div className="box resetpasswordBox">
                            <div className='triangleBox'/>
                            {Object.entries(passwordValid).map(([key,value],index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className='columns mb-1'>
                                            <div className='column is-narrow' style={{"paddingBottom" : "0"}}>{value ? VIcon : XIcon}</div>
                                            <div className='column is-narrow' style={{"paddingBottom" : "0"}}>{key}</div>
                                        </div>
                                    </React.Fragment>)
                            })}
                            </div>

                            <h1 className="title is-6 mb-3 mt-5 ml-1">Confirm Pasword *</h1>
                            <input className={"input "+(passwordConfirm !== password ? "is-danger" :"")} type={"password"} placeholder={"Confirm Password"} onChange={(e) => setPasswordConfirm(e.currentTarget.value)} value={passwordConfirm} />
                            <div className={(passwordConfirm !== password ? "custom-disableddiv" : "")}>

                                <button className={"button notabackcolor has-text-white is-fullwidth mt-5 "+(passwordConfirm !== password ? "custom-disabled " : " ")+isLoading}  onClick={() => confirmPass()}>Confirm Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>}/>);
}

export default ResetPass;