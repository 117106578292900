

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import AddCourseModal from '../AddCourseModal/AddCourseModal';
import { ButtonStyle } from '../../MainFrame'
import { backend, getJwt, hatIconImg, plusIconImg, pythonUrl } from '../../../../Data/functions';
import { setDataVar } from '../../../../redux/data';
import './CoursesComp.css'


const CoursesComp = ({lblvisible,className}) =>
{
    const { courses,courseModal } = useSelector((state) => state.data);
    const { rememberme } = useSelector((state) => state.user);
    
    const [courseAddModal,setCourseAddModal] = useState("");
    const [cookies,setCookies,] = useCookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();

  
    const deleteFunc = async (val) =>
    {
        let params = 
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
        }
        await backend(pythonUrl+"/user/course/"+val+"/",params,["body",setCookies,rememberme],dispatch);
    }
    
    return (<center>
                <div className={className}>
                    <AddCourseModal courseAddModal={courseAddModal} setCourseAddModal={setCourseAddModal}/>
                    {lblvisible ? <h1 className="title is-5 mb-4 left-buttons is-text-left ml-3">My Courses</h1> : null}
                    <div className='buttons is-text-left'>
                        {
                            courses.map((val,index) => {return (
                                <React.Fragment key={index}>
                                    <ButtonStyle Icon={hatIconImg} index={index} iconclass={"is-green-rounded"} UpperText={val.code} 
                                        LowerText={val.course} isdelete={true} onDeleteData={[setDataVar,courses,index,deleteFunc]} primaryKey={val.course}
                                        onClick={() => 
                                        {
                                            navigate("/course/"+val.course);
                                            if(courseModal !== undefined)
                                                dispatch(setDataVar({courseModal : ""}));
                                        }} />
                                </React.Fragment>)
                            })
                        }
                        <ButtonStyle onClick={() => setCourseAddModal("is-active")} Icon={plusIconImg} iconclass={"is-blue-rounded"} UpperText={"Add Course"} LowerText={"Don’t worry, you’ve got this!"}/>
                        <div></div>
                    </div>
                </div>
            </center>
    )
}

export default CoursesComp;