import React from "react";
import { useSelector } from "react-redux";

import MainFrame from "../MainFrame";
import CoursesComp from "../Courses/CoursesComp/CoursesComp";
import paryIcon from '../../../Media/partyIcon.svg'

const HomePage = () =>
{
    const { fullname } = useSelector((state) => state.user);

    return (<MainFrame childrens={
                <>
                    <div className="columns is-centered is-vcentered mt-6 mb-1">
                        <div className="column is-narrow m-0 p-0">
                            <img src={paryIcon} alt="party logo"></img>
                        </div>
                        <div className="column is-narrow m-0 p-0  ml-2">
                            <h1 className="title is-4">{fullname}, Welcome Back!</h1>
                        </div>
                    </div>
                    <center><h1 className="subtitle is-5">What can Nota help you with today?</h1></center>
                    <div className="fitt">
                        <CoursesComp lblvisible={true} className={"coursesbox mt-5"}/>
                    </div>
                </>
            }/>);
}


export default HomePage;