/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect} from 'react'


import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core'; 

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({fileURL,page,setPage,setMaxPages,zoomPluginInstance}) =>
{

    const pageNavigationPluginInstance = pageNavigationPlugin();
    useEffect(() => {setMaxPages()},[])
    useEffect(() => {pageNavigationPluginInstance.jumpToPage(page);},[page])
  return (
      <>
        {fileURL && fileURL !== undefined ?
        <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <Viewer fileUrl={fileURL} plugins={[pageNavigationPluginInstance,zoomPluginInstance]} onDocumentLoad={e => setMaxPages(e.doc._pdfInfo.numPages-1)} onPageChange={(e) => setPage(e.currentPage)}/>
            </Worker>
        </> : <>No pdf file selected</>}
      </>
  )
}

export default PdfViewer;
