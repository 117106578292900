import { combineReducers } from "redux";
import user from "./user.js";
import data from "./data.js";


// --------------------------------------------------------------
const rootReducer = combineReducers({
  user,data
});

// --------------------------------------------------------------
export default rootReducer;