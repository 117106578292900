import React, { useState } from "react";

import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import AuthFrame from "../AuthFrame";

import { backend, pythonUrl } from "../../../Data/functions";
import './ForgotPass.css'

const type = "email";

const ForgetPassFrame = () => 
{
    const [error,setError] = useState({});
    const [data,setData] = useState({});
    const [isEmailSent,] = useState(false);
    const [isloading,setIsLoading] = useState("");
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();

    const sendForgotPass = async () =>
    {
        setIsLoading("is-loading");
        try
        {
            let params = 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'  },
            }
            let res = await backend(pythonUrl+"/user/"+data[type]+"/password/reset",params,[false,setCookies,null],dispatch);
            if(res.status === 400)
                setError({...error,[type] : "User's email wasn't verified"})
            else if(res.status === 404  || res.status === 400)
                setError({...error,[type] : "User wasn't found"})
            else if(res.status === 200)
               alert("Reset email was sent to the user")
            else
            setError({...error,[type] : res.data})
        }
        catch(err)
        {
            alert(err);
        }
        setIsLoading("");
    }
    return (
    <AuthFrame childrens={<>
      <div className="box forgotbox">
        <div className="m-4">
            <div className="columns">
                <div className="column is-narrow mr-0 pr-0">
                    <div className="lockIcon"/>
                </div>
                <div className="column is-narrow">
                    <h1 className="title is-5">Can't Log in?</h1>
                    <h1 className="subtitle is-6">Restore access to your account</h1>
                </div>
            </div>
            {isEmailSent ?
            <>

            </>
            :
            <>
                <h1 className="title is-6 mb-3 mt-5 ml-1">We’ll send a recovery link to</h1>
                <input className={"input "+(error[type] ? "is-danger has-text-danger" : "")} type={"text"} placeholder={"Your email address"} value={error[type] ? error[type] : data[type]} onClick={() => setError({...error,[type] : false})} onChange={(e) => {setData({...data,[type] : e.currentTarget.value})}}/>
                <button className={"button notabackcolor has-text-white mt-4 is-fullwidth "+isloading} onClick={() => sendForgotPass()}>Reset Password</button>
            </>
            }
        </div>
        
       
      </div>
    </>}/>);
}
export default ForgetPassFrame;