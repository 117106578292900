
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {backend, getJwt, pythonUrl} from '../../../Data/functions'

const InputMessage = ({text}) =>
{
    return (<div className=''>
                <div className='inputMsg has-text-white mb-2 mr-3'>
                    <div className='p-3 pl-4'>{text}</div>
                </div>
            </div>)
}
const OutputMessage = ({text}) =>
{
    return  (<div className=''>
                <div className='outMsg has-text-black mb-2 ml-2'>
                    <div className='p-3 pl-4'>{text}</div>
                </div>
            </div>)

}
export const ModuleChat = ({chatVisible,moduletitle,modulename}) =>
{

    const [messeges,setMesseges] = useState([]);
    const [loadingBtn,setLoading] = useState("");
    const [msgBox,setMsgBox] = useState("");
    const {coursetitle} = useParams();
    const scrolldiv = useRef();
    const { rememberme } = useSelector((state) => state.user);
    const [cookies, setCookies,] = useCookies();
    const dispatch = useDispatch();
    const sendQuery = async () =>
    {   
        setLoading("is-loading");
        const addEl = [...messeges,<React.Fragment key={messeges.length}><InputMessage text={msgBox+""} /></React.Fragment>];
        setMesseges(addEl);
        setMsgBox("");
        let params = 
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
            data : new URLSearchParams({"query" : msgBox})
        }
        let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/"+moduletitle+"/"+modulename+"/query/bullets/query/chat",params,["body",setCookies,rememberme],dispatch);
        if(res.status === 500)
            alert("Couldn't understand the question")
        else
            setMesseges([...addEl,<React.Fragment key={messeges.length}><OutputMessage text={res.data.answer} /></React.Fragment>]);
        //setMesseges([...messeges,<React.Fragment key={messeges.length}><InputMessage text={msgBox} /></React.Fragment>]);
        setLoading("");
        scrolldiv.scrollTop = scrolldiv.scrollHeight;
    }

    const getHistory = async () =>
    {   
        let params = 
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
            data : new URLSearchParams()
        }
        let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/"+moduletitle+"/"+modulename+"/query/chat/history/",params,["body",setCookies,rememberme],dispatch);
        let messeges = [];
        res.data = res.data[0]
        for(let i=0;i<res.data.length;i++)
        {
           messeges.push(<React.Fragment key={messeges.length}><InputMessage text={res.data[i].query+" "} /></React.Fragment>)
           messeges.push(<React.Fragment key={messeges.length}><OutputMessage text={res.data[i].answer} /></React.Fragment>)
        }
        setMesseges(messeges)
    }
    useEffect(() => { getHistory() },[modulename])

    return  (chatVisible ? 
                <div className='chatdiv box'>
                    <div className='chatScroll scrollY scrollbarDiv' ref={scrolldiv}>
                        {messeges}
                    </div>
                    <div className='chatarea'>
                        <textarea className='textarea fitt' placeholder="How can I help?" value={msgBox} onChange={(e) => setMsgBox(e.currentTarget.value)}/>
                        <button className={'button is-fullwidth notabackcolor has-text-white mt-2 '+loadingBtn} 
                            onClick={() => {sendQuery()}}>Generate</button>

                    </div>
                </div>
            : <></>);
}