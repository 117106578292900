
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { backend, getJwt, pythonUrl } from '../../../Data/functions';
import { setDataVar } from '../../../redux/data';
import { Field } from '../Courses/AddCourseModal/AddCourseModal';


const changeKeys = (data,typeLectureTitle,typeLectureDescrip) =>
{
    data["module"] = data[typeLectureTitle];
    data["description"] = data[typeLectureDescrip];
    delete data[typeLectureTitle];
    delete data[typeLectureDescrip];
    return data;
}
const AddModuleModal = ({modal,setModal,titleType,descripType,moduleAry,moduleTitle}) =>
{
    const [data,setData] = useState({[descripType] : "",[titleType] : ""});
    const [isloading,setIsloading] = useState("");
    const [cookies, setCookies,] = useCookies();
    const { rememberme } = useSelector((state) => state.user);
    const {coursetitle} = useParams();
    const dispatch = useDispatch();
    const addModule = async () =>
    {
        setIsloading("is-loading");
        try
        {
            let params = 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
                data : new URLSearchParams({[titleType] : data[titleType],[descripType] : data[descripType]}) 
            }
            let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/"+moduleTitle+"/"+data[titleType]+"/",params,["head",setCookies,rememberme],dispatch);
            if(res.status === 201)
            {
                dispatch(setDataVar({[moduleTitle+"s"] : [...moduleAry,{...changeKeys({...data},titleType,descripType),"created_at" : new Date().getTime()/1000}]}))
                setModal("");
                setData({[descripType] : "",[titleType] : ""})
            }
            else
                alert(res.data)
        }
        catch(err)
        {
           
        }
        setIsloading("");
    }
    const formalTitle = moduleTitle[0].toUpperCase() + moduleTitle.slice(1);
    const isrequired = (data[titleType] === "" || data[titleType] === undefined);
    return (
        <>
            <div className={"modal "+modal}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <section className="modal-card-body modal-rounded-box">
                        <button className="delete is-pulled-right" aria-label="close" onClick={() => setModal("")}></button>
                        <div className='m-4'>
                            <center><h1 className='title is-5'>Add {formalTitle}</h1></center>
                            <center><h1 className='subtitle is-6 mt-2'>Feel free to participate, we’ll take care of the notes.</h1></center>
                            
                            
                            <Field label={formalTitle+" Title *"} placeholder={formalTitle+" Name"} type={titleType} data={data} setData={setData}/>

                            <div className='columns m-0 p-0 mt-5'>
                                <div className='column p-0 m-0   mb-2'>
                                    <h1 className='title is-6 is-text-left'>{formalTitle+" Description"}</h1>
                                </div>
                                <div className='column p-0 m-0   mb-2'>
                                    <h1 className='subtitle is-6 is-pulled-right'>{data[descripType].length+" / 400"}</h1>
                                </div>
                            </div>
                            <textarea className="textarea" placeholder={'What’s this '+moduleTitle+' about?'} onChange={(e) => setData({...data,[descripType] : e.currentTarget.value.slice(0,400)})} value={data[descripType]}/>
                            <div className={(isrequired ? "custom-disableddiv" : "")}>
                                <button className={'button notabackcolor has-text-white is-fullwidth mt-5 ' + (isrequired ? "custom-disabled " : " ")+isloading} onClick={() => addModule()}>Add {formalTitle}</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default AddModuleModal;