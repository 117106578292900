import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonStyle } from "../MainFrame";
import { backend, getJwt, pythonUrl } from "../../../Data/functions";
import { setDataVar } from "../../../redux/data";
import AddModuleModal from "./AddModuleBtn";
import "./ModuleBtn.css";

const ModalComp = ({moduleAry,moduletitle,Icon,addBtnIcon}) =>
{
    const { rememberme } = useSelector((state) => state.user);
    const {coursetitle} = useParams();
    const [cookies, setCookies] = useCookies();

    const [moduleModal,setModuleModal] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const moduleFormalTitle = moduletitle[0].toUpperCase()+moduletitle.slice(1);
    const deleteFunc = async (val) =>
    {
        let params = 
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
        }
        await backend(pythonUrl+"/user/course/"+coursetitle+"/"+moduletitle+"/"+val+"/",params,["body",setCookies,rememberme],dispatch);
    }
    return(
        <center>
            <div className="coursesbox mt-5">
                <AddModuleModal modal={moduleModal} setModal={setModuleModal} titleType={moduletitle} descripType={moduletitle+"descript"} moduleAry={moduleAry} moduleTitle={moduletitle}/>
                <h1 className="title is-5 mb-4 is-text-left ml-3">My {moduleFormalTitle}s</h1>
                <div className='buttons'>
                    {
                        moduleAry.map((val,index) => {return (
                            <React.Fragment key={index}>
                                <ButtonStyle Icon={Icon} index={index} iconclass={"is-green-rounded"} UpperText={val.module} 
                                    LowerText={new Date(val.created_at*1000).toLocaleDateString("en-GB")} isdelete={true} onDeleteData={[setDataVar,moduleAry,index,deleteFunc]}
                                    onClick={() => {navigate("/course/"+coursetitle+"/"+moduletitle+"/"+val.module)}} primaryKey={val.module} />
                            </React.Fragment>)
                        })
                    }

                    <ButtonStyle onClick={() => setModuleModal("is-active")} Icon={addBtnIcon} iconclass={"is-blue-rounded"} UpperText={"Add "+moduleFormalTitle} LowerText={"Don’t worry, you’ve got this!"}/>
                    <div></div>
                </div>
            </div>
        </center>
    )
}

export default ModalComp;