import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useDispatch } from "react-redux";
import { useGoogleLogin } from '@react-oauth/google';

import { backend, googleIconImg, pythonUrl } from "../../../Data/functions";
import { setVar } from "../../../redux/user";

import AuthFrame from "../AuthFrame";
import './Login.css'

const typeemail = "email";
const typepassword = "password";
const typegoogletoken = "google_token";

export const Field = ({type,othertype,placeholder,data,setData,error,setError,showIcon}) =>
{
    const formalType = type[0].toUpperCase()+type.slice(1);
    return (
        <div className="field mb-5">
            <h2 className="title is-6 m-0 mb-2">{formalType+" *"}</h2>
            <div className="is-flex is-flex-direction-row">
              <input className={"input is-rounded "+(!!error[type] ? "is-danger has-text-danger" : "")} 
                type={othertype ? othertype : type.toLowerCase()} placeholder={placeholder} 
                onClick={(e) => e.currentTarget.value === (error[type]+" "+formalType) ? setError({...error,[type] : false}) : null}
                value={!!error[type] ? error[type]+" "+formalType : data[type]} 
                onChange={(e) => {setError({...error,[type] : false});setData({...data,[type] : e.currentTarget.value})}}
              />
              {showIcon}
            </div>
      </div>
    );
}
const LoginFrame = () => 
{ 
    const [error,setError] = useState({[typeemail] : false,[typepassword] : false});
    const [data,setData] = useState({[typeemail] : "",[typepassword] : "",[typegoogletoken] : null});
    const [isloading,setIsLoading] = useState({});
    const [showpass,setShowpass] = useState(false);
    const [rememberpass,setRememberpass] = useState(false);

    const login = useGoogleLogin(
    {
      onSuccess: tokenResponse => 
      {
        setData({[typeemail] : typegoogletoken,[typegoogletoken] : tokenResponse.access_token})
        console.log(tokenResponse)
        sendLogin({[typeemail] : typegoogletoken,[typegoogletoken] : tokenResponse.access_token});
      },
    });

    const [, setCookies, ] = useCookies();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sendLogin = async (datacurr) =>
    {
        setIsLoading({...isloading,"login" : true});
        //try 
        //{
          let dataform = {[typepassword] : datacurr[typepassword]};
          if(datacurr[typegoogletoken] !== null)
            dataform[typegoogletoken] =  datacurr[typegoogletoken];

            let params = 
          {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded'  },
              data: new URLSearchParams(dataform)
          }
          let res = await backend(pythonUrl+"/user/"+datacurr[typeemail]+"/login/",params,["body",setCookies,rememberpass],dispatch);
          if(res.status === 404)
            setError({...error,[typeemail] : "Incorrect"})
          else if(res.status === 401 || res.status === 400)
            setError({...error,[typepassword] : "Invalid"})
          else if(res.status === 200)
          {
            dispatch(setVar({"isvalid" : true,"userEmail" : datacurr[typeemail],"rememberme" : rememberpass }));
            navigate("/");
          }
          setIsLoading({...isloading,"login" : false});
      
        //}
        //catch(err)
        //{
        //  alert(err);
        //  setIsLoading({...isloading,"login" : false});
        //}
    }

    return (
    <AuthFrame childrens={<>
      <div className="box loginBox mt-2">
        <div className="m-4">
          <h1 className="title is-5">Welcome Back 👏</h1>
          <h1 className="subtitle is-6">We’ve missed you!</h1>

          <button className={"button is-fullwidth "} onClick={() => login()}>
            {googleIconImg}<span className="ml-2">Sign In/Up with Google</span>
          </button>
          

          <div className="is-divider" data-content="continue"></div>

          <Field type={typeemail} data={data} setData={setData} error={error} setError={setError} placeholder={"Enter your "+typeemail}/>
          <div className="m-3"></div>
          <div className="is-relative">
            <Field type={typepassword} othertype={showpass || !!error[typepassword] ? "text" : false} 
                data={data} setData={setData} error={error} setError={setError} 
                showIcon={
              <span className="inputIcon" onClick={() => setShowpass(!error[typepassword] ? !showpass : showpass)} >
                <i className={"fa-regular fa-eye" + ((!showpass || !!error[typepassword]) ? "-slash" : "")}/>
              </span>} placeholder={"Enter your "+typepassword}/>
          </div>
          <div className="columns mb-0 pb-0">
            <div className="column">
              <div className="is-pulled-left">
                
                <label className="checkbox notaforecolor">
                  <input className="custom-checkbox" type="checkbox" onChange={(e) => setRememberpass(e.currentTarget.checked)} checked={rememberpass}/>
                  {" Remember Me"}
                </label>
              </div>
            </div>
            <div className="column">
              <div className="is-pulled-right">
                <Link to="/forgotPass" className="notaforecolor" id="forgetpass" replace><b>Forgot Your Password?</b></Link>
              </div>
            </div>
          </div>
          <div>
            <div className="field">
              <center>
                  <button className={"button notabackcolor has-text-white is-rounded is-fullwidth" + (isloading["login"] ? " is-loading" : "")}
                      onClick={() => {setData({...data,[typegoogletoken] : null});sendLogin(data)}}>Sign In</button>
              </center>
            </div>
          </div>
          <center className="mt-4">{"Don’t have an account yet? "}<Link to="/signup" className="notaforecolor" replace><b>Sign Up</b></Link></center>
        </div>
      </div>
    </>}/>);
}

export default LoginFrame;