
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { backend, getJwt, pythonUrl } from '../../../../Data/functions';
import { setDataVar } from '../../../../redux/data';

import './AddCourseModal.css'

const typeCourseTitle = "course";
const typeCourseCode = "code";
const typeCourseDescrip = "coursedescription";

export const Field = ({label,placeholder,type,data,setData}) =>
{
    return (
        <>
            <h1 className='title is-6 mb-2 mt-5 is-text-left'>{label}</h1>
            <input className='input'  placeholder={placeholder} onChange={(e) => setData({...data,[type] : e.currentTarget.value})} value={data[type]}></input>
        </>
    );
}

const AddCourseModal = ({courseAddModal,setCourseAddModal}) =>
{
    const [data,setData] = useState({[typeCourseDescrip] : ""});
    const [isloading,setIsloading] = useState("");
    
    const [cookies, setCookies, ] = useCookies();

    const { rememberme } = useSelector((state) => state.user);
    const { courses } = useSelector((state) => state.data);

    const dispatch = useDispatch();
    const addCourse = async () =>
    {
        setIsloading("is-loading");
        try
        {
            let params = 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
                data : new URLSearchParams({[typeCourseDescrip] : data[typeCourseDescrip],[typeCourseCode] : data[typeCourseCode]}) 
            }
            let res = await backend(pythonUrl+"/user/course/"+data[typeCourseTitle]+"/",params,["head",setCookies,rememberme],dispatch);
            if(res.status === 201)
            {
                dispatch(setDataVar({"courses" : [...courses,{...data}]}))
                setCourseAddModal("");
                setData({[typeCourseDescrip] : ""})
            }
            else
                alert(res.data)
        }
        catch(err)
        {
           
        }
        setIsloading("");
    }
    const isrequired = (data[typeCourseTitle] === "" || data[typeCourseTitle] === undefined);
    return (
        <>
            <div className={"modal "+courseAddModal}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <section className="modal-card-body modal-rounded-box">
                        <button className="delete is-pulled-right" aria-label="close" onClick={() => setCourseAddModal("")}></button>
                        <div className='m-4'>
                            <center><h1 className='title is-5'>Add Course</h1></center>
                            <center><h1 className='subtitle is-6 mt-2'>Don’t worry, we’ll make this term a breeze!</h1></center>
                            
                            
                            <Field label={"Course Title *"} placeholder={"Course Name"} type={typeCourseTitle} data={data} setData={setData}/>
                            <Field label={"Course Code"} placeholder={"Course Code"}    type={typeCourseCode} data={data} setData={setData}/>

                            <div className='columns m-0 p-0 mt-5'>
                                <div className='column p-0 m-0   mb-2'>
                                    <h1 className='title is-6 is-text-left'>{"Course Description"}</h1>
                                </div>
                                <div className='column p-0 m-0   mb-2'>
                                    <h1 className='subtitle is-6 is-pulled-right'>{data[typeCourseDescrip].length+" / 400"}</h1>
                                </div>
                            </div>
                            <textarea className="textarea" placeholder='What’s this course about?' onChange={(e) => setData({...data,[typeCourseDescrip] : e.currentTarget.value.slice(0,400)})} value={data[typeCourseDescrip]}/>
                            <div className={(isrequired ? "custom-disableddiv" : "")}>
                                <button className={'button notabackcolor has-text-white is-fullwidth mt-5 ' + (isrequired ? "custom-disabled " : " ")+isloading} onClick={() => addCourse()}>Add Course</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default AddCourseModal;