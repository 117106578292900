/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { backend, documentIconImg, getJwt, hatIconImg, lectureIconImg, plusIconImg, pythonUrl, uploadIconImg } from "../../../../Data/functions";
import { NavBarButton } from "../../NavBarComp/NavBarComp";
import MainFrame from "../../MainFrame";
import ModalComp from "../../Module/ModuleBtn";
import { useCookies } from "react-cookie";
import { setDataVar } from "../../../../redux/data";
import { useEffect } from "react";

const CoursePage = () =>
{    
    const {userEmail,rememberme} = useSelector((state) => state.user);
    const {lectures,readings} = useSelector((state) => state.data);
    const [cookies, setCookies, ] = useCookies();
    
    const {coursetitle} = useParams();
    const dispatch = useDispatch();

    const getModule = async (moduleTitle) =>
    {
        if(userEmail !== undefined && coursetitle !== undefined)
        {
            let params = 
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization"  : getJwt(cookies,rememberme)  },
            }
            let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/"+moduleTitle+"/",params,["body",setCookies,rememberme],dispatch);
            if(res.status === 200)
                dispatch(setDataVar({ [moduleTitle] : [...res.data]}));
            else
                alert(res.data);
        }
    }
    useEffect(() => 
    {
        getModule("lectures"); 
        getModule("readings"); 
    },[coursetitle,userEmail])
    console.log(readings)
    return (
        <MainFrame childrens={
            <>     
                <div className="columns is-centered is-vcentered mt-6 mb-1">
                    <div className="column is-narrow m-0 p-0">
                        <span className="icon is-green-rounded">
                            {hatIconImg}
                        </span>
                    </div>
                    <div className="column is-narrow m-0 p-0  ml-2">
                        <h1 className="title is-4">{coursetitle}</h1>
                    </div>
                </div>
                <ModalComp moduleAry={readings} moduletitle={"reading"} Icon={documentIconImg} addBtnIcon={uploadIconImg}/>
                <ModalComp moduleAry={lectures} moduletitle={"lecture"} Icon={lectureIconImg} addBtnIcon={plusIconImg}/>

            </>
        } navBarChildren={<NavBarButton />}/>
    );
}

export default CoursePage;