/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import { backend, getJwt, pythonUrl } from "../../Data/functions";
import { useNavigate } from "react-router-dom";
import { setVar } from "../../redux/user";
import NavBarComp from "./NavBarComp/NavBarComp";
import './MainFrame.css'
import { setDataVar } from "../../redux/data";


export const ButtonStyle = ({onClick=() => {},onDeleteData,Icon,iconclass,UpperText,LowerText,isdelete,primaryKey}) =>
{
    const [additionClass,setAdditionClass] = useState("");
    const dispatch = useDispatch();

    const removeButton = () =>
    {
      let setButtons = onDeleteData[0];
      let buttons = onDeleteData[1];
      let index = onDeleteData[2];
      buttons.splice(index, 1);
      dispatch(setButtons({buttons}));
      onDeleteData[3](primaryKey);
    }

    return (
        <>
            <div className={'box pb-0 pt-4 buttonStyle '+additionClass}>
              <div className="is-relative">
                {isdelete ? <button className="delete is-absulote-right  is-pulled-right mt-0" onClick={() => removeButton()}/> : null}
              </div>
              <div onClick={() => {onClick();}} onMouseDown={() => setAdditionClass("buttonStyleClick")} onMouseUp={() => setAdditionClass("")}>
                <div className="columns is-vcentered fullHeight mb-0 p-0 p-1">
                  <div className="column is-narrow is-vcentered">
                    <span className={"icon " +iconclass}>{Icon}</span>  
                  </div>
                  <div className="column is-narrow pl-1">
                      <h1 className="title is-5 is-text-left">{UpperText.substring(0,12)+(UpperText.length > 12 ? "..." : "")}</h1>
                      <h1 className="subtitle is-6">{LowerText.substring(0,30)+(LowerText.length > 30 ? "..." : "")}</h1>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}
const MainFrame = ({childrens,navBarChildren}) => 
{
    const { isvalid } = useSelector((state) => state.user);
    const { rememberme } = useSelector((state) => state.user);
    const [cookies, setCookies, ] = useCookies();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() =>
    {
        const getCourses = async () =>
        {
            try
            { 
                let  rememberVar = rememberme === undefined ? true : rememberme;
                let  jwt = getJwt(cookies,rememberVar);
                if(jwt !== undefined)
                {
                    const jwtext = JSON.parse(atob(jwt.split('.')[1]));
                    let params = 
                    {
                        method: 'GET',
                        headers: { "Authorization"  : jwt  },
                    }
                    let res = await backend(pythonUrl+"/user/courses/",params,["head",setCookies,rememberVar],dispatch);
                    dispatch(setDataVar({ "courses" : [...res.data]}));
                    console.log(jwtext)
                    dispatch(setVar({"userEmail" : jwtext.user,plan: jwtext.plan,"fullname" : jwtext.fullname,'picture' : jwtext.picture,"rememberme" : rememberVar}));
                }
                else
                {
                    alert("re-login required.");
                    dispatch(setVar({"isvalid" : false}));
                }
            }
            catch(err) {alert(err);}
        }
        if(isvalid === true)
            getCourses();
        else
            navigate(isvalid);
            
    },[])

    return (<div className="fitt">
              <NavBarComp navBarChildren={navBarChildren}/>
              <div className="UnderNavBar">
                {childrens}
              </div>
            </div>);
}

export default MainFrame;