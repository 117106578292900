
import React from 'react';
import {chatIconImg, XIconImg} from '../../../Data/functions'

export const ModuleChatBtn = ({chatVisible,setChatVisible}) =>
{

    return  (<div className="chatIcon">
                <div className='fitt fullcentered' onClick={() => setChatVisible(!chatVisible)}>{chatVisible ? XIconImg : chatIconImg}</div>
             </div>);
}