/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { backend, getJwt, pauseIconImg, playIconImg, pythonUrl } from "../../../../Data/functions";
import { NavBarButton } from "../../NavBarComp/NavBarComp";
import MainFrame from "../../MainFrame";
import soundBars from '../../../../Media/soundBars.webm';
import soundBarsloop from '../../../../Media/soundBarloop.gif';
import './LecturePage.css'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { SpecialInput } from "../../Reading/ReadingPage/ReadingPage";
import { ModuleChat } from "../../Module/ModuleChat";
import { ModuleChatBtn } from "../../Module/ModuleChatBtn";

let mediaRecorder;
let myVar;

const GifPlayer = ({setLines}) => 
{
    const videoRef = useRef(null);
    const handleVideoEnded = () => setLines(2);

    return (
        <video ref={videoRef} autoPlay loop={false} onEnded={handleVideoEnded}style={{ maxWidth: '100%' }}>
        <source src={soundBars} type="video/webm" />
        </video>
    );
};

  
const LecturePage = () =>
{
    const [isplaying,setPlaying] = useState(false);
    const [audioBlob,setAudioBlob] = useState(undefined);
    const [originalText,setOriginalText] = useState();
    const [pageData,setPageData] = useState();
    const [lines,setLines] = useState(0);
    const [page,setPage] = useState(-1);
    const [playDisabled,setPlayDisabled] = useState(false);
    const [chatVisible,setChatVisible] = useState(false);

    const { rememberme,userEmail } = useSelector((state) => state.user);
    const {coursetitle,lecturetitle} = useParams();
    const [cookies, setCookies, ] = useCookies();
    const dispatch = useDispatch();

    const getPage = async () =>
    {
        let params = 
        {
            method: 'GET',
            headers: { "Authorization"  : getJwt(cookies,rememberme)  },
            data : new URLSearchParams({})
        }
        let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/lecture/"+lecturetitle+"/page/"+page,params,["body",setCookies,rememberme],dispatch);
        if(res.status === 200)
        {
            setPageData({"bulletpoints" :
            res.data.bullet_points.map((point,index) => 
            <React.Fragment key={index}>
                <div className="point" key={`point-${index}`}>
                    <i className="fas fa-chevron-circle-right"/>
                    {point.text.trim()}
                </div>
            </React.Fragment>),...res.data});
        }
        else
            alert(res.data);
    }
    const getTranscript = async () =>
    {
        let params = 
        {
            method: 'GET',
            headers: { "Authorization"  : getJwt(cookies,rememberme)  },
            responseType: 'blob',
            params : {"data_format" : "file"} 
        }
        let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/lecture/"+lecturetitle+"/",params,["body",setCookies,rememberme],dispatch);
        if(res.status === 200)
            setAudioBlob(res.data);

        params = 
        {
            method: 'GET',
            headers: { "Authorization"  : getJwt(cookies,rememberme)  },
            params : {"data_format" : "json"} 
        }
        res = await backend(pythonUrl+"/user/course/"+coursetitle+"/lecture/"+lecturetitle+"/",params,["body",setCookies,rememberme],dispatch);
        if(res.status === 200)
            setOriginalText(res.data);
    }
    const startRecord = async () =>
    {
        let audioChunks = [];

        const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true,});
        mediaRecorder = new MediaRecorder(stream, {mimeType: "audio/webm",});
        setLines(1);
        mediaRecorder.ondataavailable = (event) => 
        {
            audioChunks.push(event.data);
        };
        mediaRecorder.onstop = async () => 
        {
            const audioBlobfile = new Blob(audioChunks, { type: 'audio/webm' });
            setAudioBlob(audioBlobfile);
            clearTimeout(myVar);
            await sendSoundFile(audioBlobfile);

        };
        mediaRecorder.start();
    }
    const sendSoundFile = async (audioblob) =>
    {
        let formData = new FormData();
        console.log(audioblob);
        formData.append("audio",new File([audioblob], "myfile.webm"));
        let params = 
        {
            method: 'PUT',
            headers: { 'Content-Type': `multipart/form-data`,"Authorization"  : getJwt(cookies,rememberme)  },
            data : formData
        }
        let res = await backend(pythonUrl+"/user/course/"+coursetitle+"/lecture/"+lecturetitle+"/",params,["body",setCookies,rememberme],dispatch);
        if(res.status === 200)
        {
           setOriginalText(res.data);
           setLines(0);
           setPage(0);
        }
        else if(res.status !== 504)
            alert(res.data);
    }
    const stopRecording = async () => 
    {
        if (mediaRecorder && mediaRecorder.state !== 'inactive')
            mediaRecorder.stop();
        mediaRecorder = null;
        setLines(3);
    }
    const handlePlayclick = async (e) =>
    {
        if(!isplaying) {setPlaying(true);startRecord();}
        else {setPlayDisabled(true);setPlaying(false);stopRecording();}
    }
    useEffect(() => 
    {
        if(userEmail !== undefined && originalText !== undefined && originalText.length !== undefined)
            getPage()
    },[page,userEmail])
    useEffect(() => 
    {
        if(userEmail !== undefined && originalText === undefined)
            getTranscript()
    },[userEmail])

    return (
        <MainFrame childrens={
            <>     
                <div className="columns is-centered is-vcentered fullHeight">
                    <div className="column box rounded-box mb-0" style={{"width" : "50%"}} >
                        <div className="columns is-vcentered m-4">
                            <div className="column m-0 p-0">
                                <h1 className="title is-6 notaforecolor ml-2"><b>Transcription</b></h1>
                            </div>
                            <div className="column m-0 p-0">
                                {originalText !== undefined ? <SpecialInput value={page} setValue={setPage} maxPage={originalText.length}/> : null}
                            </div>
                        </div>                       
                    
                        {audioBlob === undefined ?
                        <div className="columns is-vcentered">
                            <div className={"column is-narrow"+ (playDisabled ? " custom-disableddiv" : "")}>
                                <span className={"icon is-green-rounded ml-1 isButton " + (playDisabled ? "custom-disabled" : "")} onClick={(e) => handlePlayclick(e)}>
                                    <>{isplaying ? pauseIconImg : playIconImg}</> 
                                </span>
                            </div>
                        
                            <div className="column is-narrow" style={{"width":"88%"}}>
                                <div className="is-flex">
                                    {lines === 1 ? <GifPlayer setLines={setLines}/> :
                                    (lines === 2 ? <img src={soundBarsloop} alt="sound bar"/> : 
                                    ((lines === 3 ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : null)))}
                                </div>
                            </div>
                        </div>
                        : 
                        <div>
                            <audio src={URL.createObjectURL(audioBlob)} controls style={{"width" : "100%"}}/>
                            {(lines === 3 ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : null)}
                        </div>}
                 
                        <div className="transcriptDiv scrollY ml-4 scrollbarDiv">
                            {originalText !== undefined && originalText.chunks !== undefined ? 
                                originalText.chunks.map((val,index) => 
                                <React.Fragment key={index}>
                                    <div className={"isButton "+(page === index ? " has-back-yellow" : "")} onClick={() => setPage(index)}>
                                        <h1 className={"subtitle is-6 mb-0 p-4"}>
                                            {val}
                                        </h1>
                                    </div>
                                </React.Fragment>) 
                            : null}
                        </div>
                    </div>
                    <div className="column box rounded-box mb-0 mr-5">
                        <div className="m-5 fullHeight">
                            <ModuleChat chatVisible={chatVisible} moduletitle={"lecture"} modulename={lecturetitle}/>
                            <h1 className="title is-6 notaforecolor mb-1"><b>Summary</b></h1>
                            <div className="height-40 mb-2">
                                <div className={"height-85 scrollY scrollbarDiv"}>
                                    {pageData !== undefined ? pageData["summary"] : null}
                                </div>
                            </div>
                            <h1 className="title is-6 notaforecolor mb-1"><b>Bullet Points</b></h1>
                            <div className="height-50 scrollY scrollbarDiv">
                                <div className="points-container">
                                    {pageData !== undefined ? pageData["bulletpoints"] : null}
                                </div>
                            </div>
                        </div>
                    </div>                     
                <ModuleChatBtn chatVisible={chatVisible} setChatVisible={setChatVisible}/>
                </div>
            </>
        } navBarChildren={<NavBarButton />}/>
    );
}

export default LecturePage;