import axios from 'axios';
import { setVar } from '../redux/user';
import HatIcon from '../Media/Icons/HatIcon.svg';
import PlusIcon from '../Media/Icons/PlusIcon.svg';
import DocIcon from '../Media/Icons/documentIcon.svg';
import uploadIcon from '../Media/Icons/uploadIcon.svg';
import lectureIcon from '../Media/Icons/lectureIcon.svg';
import playBtn from '../Media/Icons/playBtn.svg';
import pauseIcon from '../Media/Icons/pauseIcon.svg';
import documentIcon from '../Media/Icons/documentIcon.svg';
import uploadFileIcon from '../Media/Icons/uploadFileIcon.svg';
import chatIcon from '../Media/Icons/chatIcon.svg';
import XIconIcon from '../Media/Icons/XIconImg.svg';
import googleIcon from '../Media/Icons/googleIcon.svg'

var currUrl = "";
var pythonUrl = "";

if(window.location.href.includes("localhost"))
{
    currUrl = "http://localhost:3009/";
    pythonUrl = "http://localhost:8080";//;
}
else
{
    currUrl = "https://app.notanotes.com";
    pythonUrl = "https://back.notanotes.com";

}
export const VIcon = <span className="icon has-text-success" ><i className="fa-solid fa-check" /></span>
export const XIcon = <span className="icon has-text-danger"><i className="fa-solid fa-x"/></span>
export const version = "V0.002";
export const randNum = (min, max) =>
{
    return Math.random() * (max - min) + min;
}
export const hatIconImg = <img src={HatIcon} width={"65%"} alt="Icon"/>;
export const plusIconImg = <img src={PlusIcon} width={"55%"} alt="Icon"/>;
export const docIconImg = <img src={DocIcon} width={"55%"} alt="Icon"/>;
export const uploadIconImg = <img src={uploadIcon} width={"55%"} alt="Icon"/>;
export const lectureIconImg = <img src={lectureIcon} width={"55%"} alt="Icon"/>;
export const playIconImg = <img className='ml-1' src={playBtn} width={"50%"} alt="Icon"/>;
export const pauseIconImg = <img src={pauseIcon} width={"50%"} alt="Icon"/>;
export const documentIconImg = <img src={documentIcon} width={"50%"} alt="Icon"/>;
export const uploadFileIconImg = <img src={uploadFileIcon} width={"70%"} alt="Icon"/>;
export const chatIconImg = <img src={chatIcon} width={"65%"} alt="Icon"/>;
export const XIconImg = <img src={XIconIcon} width={"65%"} alt="Icon"/>;
export const googleIconImg = <img src={googleIcon} width={"6%"} alt="Icon"/>;

export const setJwt = ([jwtValue,setCookie,rememberme]) =>
{
    if(jwtValue)
    {
        if(rememberme)
            setCookie("jwt",jwtValue,{"domain" : currUrl.includes("notanotes") ? ".notanotes.com" : "localhost","path" : "/"});
        else
            sessionStorage.setItem("jwt", jwtValue);
    }
}
export const getJwt = (cookies,rememberme) => { return ((rememberme || rememberme === undefined) ? cookies.jwt : sessionStorage.getItem("jwt")); }

//                         wherejwtValue,setCookie,rememberme
const backend = async (url,params,cookieHandle,dispatch) =>
{
    var isJwt = params.headers.Authorization !== undefined;
    try
    {
        if(cookieHandle[2] != null)
            params.headers.RememberMe = cookieHandle[2];
        var req = await axios({"url" : url,...params,timeout: 0 })
        const jwtValue = (cookieHandle[0] === "body") ? req.data.Authorization : (cookieHandle[0] === "head" ? req.headers.Authorization : cookieHandle[0])
        if (jwtValue !== undefined)
            setJwt([jwtValue,...cookieHandle.slice(1)]);
        return req;
   }
   catch(err)
   {
      
       if(err.response !== undefined)
       {
           if (err.response.status === 401 && isJwt)
           {
               alert("re-login required");
               dispatch(setVar({"isvalid" : false}));
           }
           return err.response;
       }
       else
       {
            console.log(err);
            if(err.code === "ERR_NETWORK")
                return {"status" : 504}
            else
                alert(err);
            //window.location.reload();
       }
   }
}

export {backend,pythonUrl,currUrl};