/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { backend, pythonUrl, setJwt } from "../../../Data/functions";
import { setVar } from "../../../redux/user";

import AuthFrame from "../AuthFrame";
import './VerifyWindow.css'


const VerifyWindow = () =>
{

    const [isloading,setIsLoading] =useState("");
    const [, setCookies, ] = useCookies();
    const navigate = useNavigate();
    const { userEmail } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const { token } = useParams();


    const resendLink = async (e) =>
    {
      setIsLoading("is-loading");
      e.currentTarget.disabled = true;
      let params = 
      {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded'  },
      }
      let res = await backend(pythonUrl+"/user/"+userEmail+"/resend-registration-email",params,[false,setCookies,null],dispatch);
      if(res.status === 200)
        alert("Done!")
      else
        alert("User Error, Please Contact us at info@notanores.com");
  
      setIsLoading("");
    }
    useEffect(() =>
    {
      if(token !== undefined && token !== "null" )
      {
        setJwt([token,setCookies,"true"]);
        dispatch(setVar({"rememberme" : true}));
        navigate("/")
      }
    },[])
    
   
    return(
        <AuthFrame childrens={
        <>
            <div className="box verifyBox mt-2">
                <div className="m-4">
                    <center>
                      <h1 className="title is-5">Please Verify Your Email.</h1>
                      <h1 className="subtitle is-6 mt-1 mb-0">A verification link has been sent to <b>{userEmail}</b></h1>
                      <h1 className="subtitle is-6">Please verify your email to continue.</h1>
                      <button className={"button notaforecolor is-fullwidth "+isloading} onClick={(e) => resendLink(e)}><b>Resend Link</b></button>
                    </center>
                </div>
            </div>
        </>
        } addClass={" top-10"}/>)
}

export default VerifyWindow;