
import { uploadFileIconImg } from "../../../../../Data/functions";
import './UploadModal.css'



const UploadModal = ({modal,setModal,setFiles,isLoading,setIsLoading}) =>
{

    const handleDrop = (event) => 
    {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {setFiles([...files]);setIsLoading(true);}
    }
    const handleDragOver = (event) => {event.preventDefault();event.currentTarget.style.opacity = 0.6;}
    const handleDragLeave = (event) => {event.preventDefault();event.currentTarget.style.opacity = 1;}
    const onClick = () =>
    {
        let input = document.createElement("input");
        input.type = "file";
        input.setAttribute("multiple", true);
        input.setAttribute("accept", ".pdf");
        input.onchange = function (event)
        {
            if(this.files.length > 0)
            {
                setFiles(this.files);
                setIsLoading(true);
            }
        };
        input.click();
    }
    return (<>
                <div className={"modal "+modal}>
                    <div className="modal-background"></div>
                    <div className="modal-card widthAuto">
                        <section className="modal-card-body modal-rounded-box ">
                            <div className="m-4">

                                <center>
                                    <h1 className="title is-4">Upload Reading</h1>
                                    {isLoading
                                    ?
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    :
                                    <div className="uploadDnD"  onDrop={handleDrop} onMouseOver={handleDragOver} onMouseLeave={handleDragLeave}
                                      onDragLeave={handleDragLeave} onDragOver={handleDragOver} onClick={onClick}
                                    >
                                        {uploadFileIconImg}
                                    </div>
                                    }
                                </center>
                                
                            </div>
                        </section>
                    </div>
                </div>
            </>);
}

export default UploadModal;