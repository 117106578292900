import { backend } from "../Data/functions";


// -------------------------------------------------------
export const setVar = (data) => async (dispatch) =>  {dispatch({type : "SET_VAR",...data});};

  

const initialState = 
{
    userEmail: undefined,
    fullname : undefined,
    isvalid : true,
    meter : undefined,
    plan : undefined,
    rememberme : undefined
};
  
// -------------------------------------------------------
const reducer = (state = initialState, action) => 
{
    switch(action.type)
    {
        case "SET_VAR":
            var data = {...action};
            delete data["type"];
            return {...state,...data}
        default:
            return state;
    }
};

export default reducer;
  