import React from "react";

import notalogo from '../../Media/notalogo.svg'
import './AuthFrame.css'

const AuthFrame = ({childrens,addClass=""}) => 
{

    return (<div className={"wrapper"+addClass}>
              <div className="logoContiner">
                <figure className="image is-3by1">
                    <img src={notalogo} alt="nota logo"/>
                </figure>
              </div>
                {childrens}
            </div>);
}

export default AuthFrame;