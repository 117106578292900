// -------------------------------------------------------
export const setDataVar = (data) => async (dispatch) =>  {dispatch({type : "SET_DATA_VAR",...data});};


const initialState = 
{
    courses : [],
    lectures : [],
    readings : [],
    courseModal : ""
};
  
// -------------------------------------------------------
const reducer = (state = initialState, action) => 
{
    switch(action.type)
    {
        case "SET_DATA_VAR":
            var data = {...action};
            delete data["type"];
            return {...state,...data}
        default:
            return state;
    }
};

export default reducer;
  