/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from "react-redux";

import { hatIconImg, version } from "../../../Data/functions";
import { setDataVar } from "../../../redux/data";
import CourseChooseModal from "../Courses/CourseChooseModal/CourseChooseModal";

import logo from "../../../Media/notalogo.png";
import userlogo from "../../../Media/userImage.png";
import './NavBarComp.css'
import { useCookies } from "react-cookie";

export const NavBarButton = () =>
{
    const dispatch = useDispatch();
    return  ( <>
                <CourseChooseModal />
                <div className="columns is-vcentered ml-3 isButton" onClick={() => dispatch(setDataVar({"courseModal" : "is-active"}))}>
                  <div className="column is-narrow m-0 p-0">
                      <span className="icon is-large">{hatIconImg}</span>
                  </div>
                  <div className="column is-narrow m-0 p-0 pt-1">
                      <h1 className="subtitle is-6 ml-1 notaforecolor"><b>{"My Courses"}</b></h1>
                  </div>
              </div>
             </>)
}

const NavBar = ({navBarChildren}) =>
{

    const { fullname,picture } = useSelector((state) => state.user);
    const [,,removeCookies] = useCookies();
    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
          <div className="navbar-brand custom-navbar-brand" style={{"height" : "70px"}}>
            <a className="navbar-item" href = "/"  style={{"height" : "55px","width" : "225px"}}>
              <div className="mt-5 mb-3 ml-4 fullWidth fullHeight">
                <img className="fullWidth fullHeight" style={{"maxHeight" : "100%"}} src={logo} alt="logo"/>
              </div>
            </a>
            <div className="navbar-item is-vcentered">
              {navBarChildren}
            </div>
            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
  
          <div className="navbar-menu">
            <div className="navbar-start"/>
            <div className="navbar-end mr-1"></div>
  
            <div className="navbar-item is-vcentered"><span className="tag is-link is-light">{version}</span></div>
            <div className="navbar-item is-vcentered">
              <button className="button backgreen notaforcolor"><b>Settings</b></button>
            </div>
            
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-item">
                  <img className="mr-3" src={picture !== undefined ? picture : userlogo} alt="user logo"/>
                  <span className="mr-2">{/*userEmail*/fullname}</span>
              </a>
              <div className="navbar-dropdown">
                <a className="navbar-item" onClick={() => {removeCookies("jwt");window.location.reload()}}>
                  Log Out
                </a>
              </div>
            </div>
          </div>
        </nav>
    );
}
export default NavBar;